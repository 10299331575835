import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';

@Injectable({
	providedIn: 'root'
})
export class LocalStorageService {
  // CR: the best here would be to change the key to be an enum - that way we won't have trouble with typos in the key names throughout the app

  constructor() { }

  isLocalStorageDefined(): boolean {
    return typeof localStorage !== 'undefined';
  }

  setItem(key: string, value: string) {
    if (this.isLocalStorageDefined()) {
      localStorage.setItem(key, value);
    }
  }

  removeItem(key: string) {
    if (this.isLocalStorageDefined()) {
      localStorage.removeItem(key);
    }
  }

  removeAll() {
		if (this.isLocalStorageDefined()) {
			localStorage.clear();
		}
	}

  getItem(key: string): string {
    if (key && this.isLocalStorageDefined()) {
    	let item = localStorage.getItem(key);

    	if (item === 'undefined') return null;
      return localStorage.getItem(key);
    }
  }

  getObjectItem<T>(key: string): T {
    const item = this.getItem(key);
    if (item) {
      return JSON.parse(item);
    }
  }

  setObjectItem<T>(key: string, obj: T) {
    this.setItem(key, JSON.stringify(obj));
  }

}
