import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';

@Injectable({
  providedIn: 'root'
})
export class ClientDetailsService {
	private clientDetails: M.ClientDetails;

  constructor() { }

  getClientDetails(): M.ClientDetails {
		return this.clientDetails;
  }

  setClientDetails(details: M.ClientDetails): void {
		 this.clientDetails = details;
  }

}
