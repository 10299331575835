import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { BrowserSelectorService } from '../../services/browser-selector/browser-selector.service';
import { MetaService } from '../../services/meta/meta.service';

@Component({
  selector: 'app',
  templateUrl: './app.view.html'
})
export class AppView implements OnInit {

  @ViewChild('landscapeMessage') elementID:ElementRef;

  isLandscape: boolean;
  isMobile: boolean;

  constructor(private el: ElementRef,
    private browserSelectorService: BrowserSelectorService,
    private metaService: MetaService) {
    this.isMobile = this.browserSelectorService.isMobile();
  }
  ngOnInit(): void {
    this.metaService.setFacebookTags(window.location.href, 'Check out my registry', 'Check out my description', 'image');
    this.isLandscape = !window.matchMedia("(orientation: portrait)").matches && screen.availHeight < screen.availWidth;
  }


  @HostListener('window:orientationchange', ['$event'])
  @HostListener('window:resize', ['$event'])
  onEvent() {
    this.isLandscape = !window.matchMedia("(orientation: portrait)").matches && screen.availHeight < screen.availWidth;
  }
}



