import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ClientDetailsService } from '../../../services/clientDetails/client-details.service';

@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	constructor(private injector: Injector ) {
	}

	navigateToLogin(error = false) {
		const clientDetailsService = this.injector.get(ClientDetailsService);
		const clientDetails =  clientDetailsService.getClientDetails();
		if (clientDetails.Features.externalLoginEnabled) {
			if (clientDetails.Features.externalLoginUrl) {
				window.location.href = clientDetails.Features.externalLoginUrl;
			} else {
				window.location.href = clientDetails.SignInUrl;
			}
		} else {
			this.navigate('login', error);
		}
	}

	hasExternalLoginPage() {
		const clientDetailsService = this.injector.get(ClientDetailsService);
		const clientDetails =  clientDetailsService.getClientDetails();
		if (clientDetails.Features.externalLoginEnabled) {
			if (clientDetails.Features.externalLoginUrl) {
				window.location.href = clientDetails.Features.externalLoginUrl;
			} else {
				window.location.href = clientDetails.SignInUrl;
				return false;
			}
		} else {
			return true;
		}
	}

	navigateToCreateEvent() {
		this.navigate('event');
	}

	private navigate(route: string, error = false) {
		const router = this.injector.get(Router);
		if (error) {
			router.navigate([`/registered/${route}`], { queryParams: { error } });
		} else {
			router.navigate([`/registered/${route}`]);
		}
	}
}
