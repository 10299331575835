import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoggerService {

    constructor() { }

    debug(message: string) {
        if (!environment.production) {
            this.log('debug', message);
        }
    }

    info(message: string, force: boolean = false) {
        if (force || !environment.production) {
            this.log('info', message);
        }
    }

    warning(message: string) {
        if (!environment.production) {
            this.log('warning', message);
        }
    }

    error(message: string, error?: any) {
        this.log('error', message, error);
    }

    private log(type: string, message: string, error?: any) {
        try {
            (console[type] || console.log)(message);
            if (error) {
                console.log(error);
            }
        }
        catch(err) {
        }

    }
}
