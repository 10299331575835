import { Injectable } from '@angular/core';
import {CurrencyDetails} from '../../app.models';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private currencyDetails: CurrencyDetails;

  constructor() { }

  getCurrencyDetails(): CurrencyDetails {
		return this.currencyDetails;
  }

  setCurrencyDetails(details: CurrencyDetails): void {
		 this.currencyDetails = details;
  }

}
