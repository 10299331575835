import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: 'root'
})
export class MetaDataService {

  constructor(private http: HttpClient) { }

  getMetaData(siteId) {
    return this.http.get('assets/data/site' + siteId + '/metadata.json');
  }
}
