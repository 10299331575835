import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private urlMeta: string = "og:url";
  private titleMeta: string = "og:title";
  private descriptionMeta: string = "og:description";
  private imageMeta: string = "og:image";
  private secureImageMeta: string = "og:image:secure_url";

  constructor(private metaService: Meta) { }
  
  public setFacebookTags(url: string, title: string, description: string, image: string): void {
    var imageUrl = 'https://www.oecd.org/media/oecdorg/directorates/environmentdirectorate/2020-2/leaves-5308582_1920.jpg';
    var tags = [
      new MetaTag(this.urlMeta, url),
      new MetaTag(this.titleMeta, title),
      new MetaTag(this.descriptionMeta, description),
      new MetaTag(this.imageMeta, imageUrl),
      new MetaTag(this.secureImageMeta, imageUrl)
    ];
    this.setTags(tags);
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      this.metaService.updateTag({ property: siteTag.name, content: siteTag.value });
    });
  }
}

export class MetaTag {
  name: string;
  value: string;

  constructor(name: string, value: string) {
      this.name = name;
      this.value = value;
  }
}
