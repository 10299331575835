import { FeaturesService } from '../features/features.service';
import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { LoggerService } from '../logger/logger.service';
import { Observable, forkJoin } from 'rxjs';
import { NetService } from '../net/net.service';
import { MetaDataService } from '../meta-data/meta-data.service';
import { Meta, Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from '../../services/utils/utils.service';
import { SpinnerService } from '../spinner/spinner.service';
import { ChatService } from '../../services/chat/chat.service';
import { StaticDataService } from '../static-data/static-data.service';
import { UserService } from '../../registered/services/user/user.service';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { ClientDetailsService } from '../clientDetails/client-details.service';
import { CurrencyService } from '../currency/currency.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SiteService {

	constructor(
		private logger: LoggerService,
		private titleService: Title,
		private metaService: Meta,
		private net: NetService,
		private utilsService: UtilsService,
		private metaDataService: MetaDataService,
		private http: HttpClient,
		private spinnerService: SpinnerService,
		private featureService: FeaturesService,
		private chatService: ChatService,
		private userServices: UserService,
		private staticDataService: StaticDataService,
		private cookieService: CookieService,
		private clientDetailsService: ClientDetailsService,
		private currencyService: CurrencyService,
		private translate: TranslateService,
	) {}

	private initFeatures(): Observable<void> {
		return new Observable(o => {
			const clientDetails = this.clientDetailsService.getClientDetails();
			const currencyDetails = this.currencyService.getCurrencyDetails();
			if (clientDetails.Features.kioskModeState === E.OptionState.On) {
				this.utilsService.checkAndSetKioskMode();
			}

			this.chatService.initChatFeature(clientDetails);

			this.featureService.initFeatures(clientDetails.ClientId,
				clientDetails.FeaturesConfiguration, currencyDetails, clientDetails.DefaultLanguage);

			o.next();
			o.complete();
		});
	}

	private handleExternalLogin() {
		return new Observable(o => {
			const clientDetails = this.clientDetailsService.getClientDetails();

			if (clientDetails.Features.externalLoginEnabled && !clientDetails.SignInUrl) {
				this.userServices.loginExternal()
				.subscribe((userData: M.UsersData) => {
					o.next();
					o.complete();
				}, error => {
						this.logger.error('loginExternal error from server', error);
						o.next();
						o.complete();
				});
			} else {
				if (!!this.cookieService.get(E.CookieKeys.Jcookielit)) {
					this.userServices.loginAuthorizedUser().subscribe( _ => {
						o.next();
						o.complete();
					});
				} else {
					o.next();
					o.complete();
				}
			}
		});
	}

	private getPageLinks(): Observable<void> {
		return new Observable(o => {
			const clientDetails = this.clientDetailsService.getClientDetails();
			this.net.getJSON<M.PageLink[]>(clientDetails.ClientId, E.StaticJsonName.PageLinks)
					.subscribe((pageLinks: M.PageLink[]) => {
						clientDetails.PageLinks = pageLinks;
						o.next();
						o.complete();
					});
		});
	}
	private getHowItWorks(): Observable<void> {
		return new Observable(o => {
			const clientDetails = this.clientDetailsService.getClientDetails();
			this.net.getJSON<M.HowItWork[]>(clientDetails.ClientId, E.StaticJsonName.HowItWorks)
					.subscribe((howItworks: M.HowItWork[]) => {
						clientDetails.HowItWorks = howItworks;
						o.next();
						o.complete();
					},
					() => {
						o.next();
						o.complete();
					});
		});
	}

	private loadCss(): Observable<void> {
		return new Observable(o => {
			const clientDetails = this.clientDetailsService.getClientDetails();
			this.utilsService.loadCss(clientDetails.ClientId);
			o.next();
			o.complete();
		});
	}

	private loadMetadata(): Observable<void> {
		return new Observable(o => {
			const clientDetails: M.ClientDetails = this.clientDetailsService.getClientDetails();
			this.metaDataService.getMetaData(clientDetails.ClientId)
					.subscribe((data: M.MetaData) => {
						const metaData = data;
						if (metaData.title) {
							this.titleService.setTitle(this.translate.instant(metaData.title));
						}

						for (const meta in metaData.meta) {
							if (metaData.meta.hasOwnProperty(meta)) {
								this.metaService.addTag({
									name: meta,
									content: metaData.meta[meta]
								});
							}
						}

						if (metaData.favicon) {
							this.utilsService.setFavicon(metaData.favicon);
						}

						o.next();
						o.complete();
					});
		});
	}

	private loadStaticJsonFiles(): Observable<void> {
		return new Observable(o => {
			const clientDetails: M.ClientDetails = this.clientDetailsService.getClientDetails();
			this.staticDataService.initStaticData(clientDetails.ClientId);
			o.next();
			o.complete();
		});
	}

	initSite(): Observable<void> {
		this.logger.info('Current Version:' + environment.version, true);
		this.spinnerService.startLoading(E.SpinnerTarget.InitSite);

		return new Observable<void>(o => {
			this.net.getClientDetails().subscribe(clientDetails => {
				this.clientDetailsService.setClientDetails(clientDetails);
				// clientDetails.CurrencyId || 1: To select $ if there is no currency id for client in db.
				this.net.getCurrencyDetails(clientDetails.CurrencyId || 1).subscribe(currencyDetails => {
					this.currencyService.setCurrencyDetails(currencyDetails);
					this.net.getJSON<M.ClientFeatures>(clientDetails.ClientId, E.StaticJsonName.Features)
						.subscribe((features: M.ClientFeatures) => {
							clientDetails.Features = features;
							this.clientDetailsService.setClientDetails(clientDetails);
							const parallelOperations = [
								this.initFeatures(),
								this.handleExternalLogin(),
								this.getPageLinks(),
								this.getHowItWorks(),
								this.loadCss(),
								this.loadMetadata(),
								this.loadStaticJsonFiles(),
								this.setReferralCookieIfNeeded()
							];

							forkJoin(...parallelOperations).subscribe(() => {
								this.spinnerService.stopLoading(E.SpinnerTarget.InitSite);
								o.complete();
							});
						});
					});
			});
		});
	}

	setReferralCookieIfNeeded(): Observable<void> {
		const cookieExpirationInDays = 365;
		const refferalParamKey = 'ref';
		return new Observable(o => {
			const url = window.location.search.replace('?', '').split('&');
			if (!url) {
				o.next();
				o.complete();
				return;
			}
			for (let urlPart of url) {
				let tempUrlArray = urlPart.split('=');
				if (tempUrlArray[0] === refferalParamKey) {
					this.cookieService.set(E.CookieKeys.RefferalCode, tempUrlArray[1], cookieExpirationInDays);
				}
			}
			o.next();
			o.complete();
		})
	}
}
