import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { take } from 'rxjs/operators';
import { NetService } from '../net/net.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StaticDataService {
	private _homePageContext: BehaviorSubject<M.HomepageContext[]> = new BehaviorSubject<M.HomepageContext[]>([]);
	private _registryBenefits: BehaviorSubject<M.RegistryBenefitsPage> = new BehaviorSubject<M.RegistryBenefitsPage>(undefined);
	private _navigationLinks: BehaviorSubject<M.CustomSiteHeaderLinks> = new BehaviorSubject<M.CustomSiteHeaderLinks>(undefined);
	private _guestNavigationLinks: BehaviorSubject<M.CustomSiteHeaderLinks> = new BehaviorSubject<M.CustomSiteHeaderLinks>(undefined);

  constructor(private netService: NetService) {}

	get homePageContext() {
		return this._homePageContext.asObservable();
	}

	get registryBenefits() {
		return this._registryBenefits.asObservable();
	}

	get navigationLinks() {
		return this._navigationLinks.asObservable();
	}

	get guestNavigationLinks() {
		return this._guestNavigationLinks.asObservable();
	}

	initStaticData(clientId: number) {
		this.getAllStaticJSON(clientId);
	}

	private getAllStaticJSON(clientId: number) {
		this.getSpecificJson<M.HomepageContext[]>(clientId, E.StaticJsonName.Context, this._homePageContext);
		this.getSpecificJson<M.RegistryBenefitsPage>(clientId, E.StaticJsonName.RegistryBenefits, this._registryBenefits);
		this.getSpecificJson<M.CustomSiteHeaderLinks>(clientId, E.StaticJsonName.CouplesNavigationLinks, this._navigationLinks);
		this.getSpecificJson<M.CustomSiteHeaderLinks>(clientId, E.StaticJsonName.GuestNavigationLinks, this._guestNavigationLinks);
  }

  getSpecificJson<T>(clientId: number, jsonName: E.StaticJsonName, subject: BehaviorSubject<T>) {
    this.netService
		.getJSON<T>(clientId, jsonName)
		.pipe(take(1))
		.subscribe((data: T) => {
			subject.next(data);
	}, () => {
		subject.next(undefined);
	});
  }
}
