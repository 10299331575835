import { Injectable } from '@angular/core';
import * as M from '../../../app.models';
import * as E from '../../../app.enums';
import { CookieService } from 'ngx-cookie-service';
import { tap, catchError, take } from 'rxjs/operators';
import { RegisteredNetService } from '../registered-net/registered-net.service';
import { LocalStorageService } from '../../../services/local-storage/local-storage.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { LoggerService } from '../../../services/logger/logger.service';
import { Router } from '@angular/router';
import { ClientDetailsService } from '../../../services/clientDetails/client-details.service';
import { UtilsService } from '../../../services/utils/utils.service';


@Injectable({
	providedIn: 'root'
})
export class UserService {
	private cookieExpirationDate: number = 7;
	private _userData: BehaviorSubject<M.UsersData> = new BehaviorSubject<M.UsersData>(undefined);
	private userIsLoggedIn: boolean = false;
	private lastAddedToListProducts = [];

	get userData(): Observable<M.UsersData> {
		return this._userData.asObservable();
	}

	constructor(
		private utilsService: UtilsService,
		private cookieService: CookieService,
		private registeredNetService: RegisteredNetService,
		private clientDetailsService: ClientDetailsService,
		private localStorageService: LocalStorageService,
		private router: Router,
		private logger: LoggerService) {
		this.userIsLoggedIn = !!(this.cookieService.get(E.CookieKeys.Jcookielit));
	}

	private getUserFromCookies(): M.LocalStoredUser {
		return {
			lastListId: this.getCurrentUserList(),
			name: this.cookieService.get(E.CookieKeys.Name)
		};
	}


	private getUserFromLocalStorage(): M.LocalStoredUser {
		return this.localStorageService.getObjectItem<M.LocalStoredUser>(
			E.LocalStorageKeys.LoggedInUser
		);
	}

	public saveUserLocalStorage(user: M.LocalStoredUser) {
		this.localStorageService.setObjectItem<M.LocalStoredUser>(
			E.LocalStorageKeys.LoggedInUser,
			user
		);
	}

	public saveUserCookies(user: M.LocalStoredUser) {
		this.cookieService.set(E.CookieKeys.Name, user.name, this.cookieExpirationDate, '/');
		if (user.lastListId) {
			this.cookieService.set(E.CookieKeys.UserListID, user.lastListId.toString(), this.cookieExpirationDate, '/');
		}
	}

	addToListProduct(listId, sku) {
		this.lastAddedToListProducts.push({listId, sku});
	}
	removeAddedToListProduct(listId, sku) {
		this.lastAddedToListProducts = this.lastAddedToListProducts.filter((item) => item.listId !== listId && item.sku !== sku);
	}
	isProductAddedToListByUser(listId, sku) {
		let isProductExist;
		isProductExist = this.lastAddedToListProducts.find((item) => {
			return item.listId === listId && item.sku === sku;
		});
		return !!isProductExist;
	}

	login(email: string, password: string, listIdToActive?: number): Observable<M.UsersData> {
		const credentials = btoa(email + ':' + password);
		return this.registeredNetService.loginAndGetUserData(credentials, '', '', '', listIdToActive).pipe(
			tap((userData: M.UsersData) => {
				this.processUserAfterLogin(userData);
			}),
			catchError(error => {
				this.logger.error('Login error from server', error);
				throw new Error('Login error from server');
			})
		);
	}

	loginAdminBackdoor(token: string, type: string, listId: string, isAdmin: string): Observable<M.UsersData> {
		return this.registeredNetService.loginAndGetUserData(token, type, listId, isAdmin).pipe(
			tap((userData: M.UsersData) => {
				this.processUserAfterLogin(userData);
			}),
			catchError(error => {
				this.logger.error('Login error from server', error);
				throw new Error('Login error from server');
			})
		);
	}


	loginWithFacebook(token: string, type: string): Observable<M.UsersData> {
		// const credentials = btoa(email + ':' + password);
		return this.registeredNetService.loginAndGetUserData(token, type).pipe(
			tap((userData: M.UsersData) => {
				this.processUserAfterLogin(userData);
			}),
			catchError(error => {
				this.logger.error('Login error from server', error);
				throw new Error(error.error || 'Login error from server');
			})
		);
	}
	loginAuthorizedUser() {
		 return this.registeredNetService.loginAuthorizedUser().pipe(
			tap((userData: M.UsersData) => {
			if (userData) {
				this.processUserAfterLogin(userData);
			} else {
				this.processUserAfterLogOut();
			}
		}),
		catchError(err => {
			this.processUserAfterLogOut();
			return throwError(err);
		}));
	}

	createUser(data: M.LoginData): Observable<M.UsersData> {
		return this.registeredNetService.registerAndGetUserData(data).pipe(
			tap((userData: M.UsersData) => {
				if (userData) {
					this.userIsLoggedIn = true;
					this._userData.next(userData);
				}
			})
		);
	}

	loginExternal(): Observable<M.UsersData> {
		return this.registeredNetService.loginExternal().pipe(
			tap((userData: M.UsersData) => {
				if (userData) {
					this.processUserAfterLogin(userData);
				} else {
					this.processUserAfterLogOut();
				}
			}),
			catchError(err => {
				this.processUserAfterLogOut();
				return throwError(err);
			})
		);
	}

	private processUserAfterLogin(userData: M.UsersData) {
		if (userData) {
			this.userIsLoggedIn = true;
			const user = this.getStoredUserData();
			let userLastListId = userData.List ? userData.List.ListId : null;

			if (user.lastListId) {
				const lastUserList = userData.Lists.find(list => list.ListId === user.lastListId);
				if (lastUserList) {
					userData.List = userData.Lists.find(list => list.ListId === user.lastListId);
					userLastListId = userData.List ? userData.List.ListId : user.lastListId;
				} else {
					user.lastListId = userData.List ? userData.List.ListId : undefined;
				}
			}

			this._userData.next(userData);
			const userToStore: M.LocalStoredUser = { name: `${userData.FirstName} ${userData.LastName}`, lastListId: userLastListId };
			this.saveUserCookies(userToStore);
			this.saveUserLocalStorage(userToStore);
		}
	}

	private processUserAfterLogOut() {
		this.userIsLoggedIn = false;
		this.cookieService.deleteAll('/');

		// TODO: Maybe we need to wrapper all realted logined user as one item object
		this.localStorageService.removeItem(E.LocalStorageKeys.LoggedInUser);
		this.localStorageService.removeItem(E.LocalStorageKeys.AvailableFunds);
		this.localStorageService.removeItem(E.LocalStorageKeys.UserDetails);
		this.localStorageService.removeItem(E.LocalStorageKeys.ShippingCorrelationId);
		this.localStorageService.removeItem(E.LocalStorageKeys.ShipToMeCartList);
	}

	isLoggedIn(): boolean {
		// make sure the cookies is not cleared.
		return this.userIsLoggedIn && (this.utilsService.isLocalHost() || !!(this.cookieService.get(E.CookieKeys.Jcookielit)));
	}


	logout(navigate = true): Observable<void> {
		return new Observable((o) => {
			return this.registeredNetService.logout().pipe(
				tap( _  => {
					this.processUserAfterLogOut();
				}))
			.subscribe(() => {
				const navigateUrl = this.clientDetailsService.getClientDetails().Features['navigateAfterLogout'];
				if (navigateUrl) {
					if (navigate) {
						window.location.href = navigateUrl;
					}
					o.next();
					o.complete();
					return;
				}
				this.router.navigate(['/']);
				o.next();
				o.complete();
			});
		});
	}

	getStoredUserData(): M.LocalStoredUser {
		let user: M.LocalStoredUser;
		user = this.getUserFromCookies();
		if (!user) {
			user = this.getUserFromLocalStorage();
		}
		return user;
	}

	sendResetPasswordEmail(userEmail: string) {
		return this.registeredNetService.sendResetPasswordEmail(userEmail).pipe(
			catchError(err => {
				this.logger.error(`error from server: ${err}`, err);
				return throwError(`error from server: ${err}`);
			})
		);
	}

	getCurrentUserList(): number {
		const userListId = this.cookieService.get(E.CookieKeys.UserListID);
		if (!userListId) {
			return undefined;
		}

		return parseInt(userListId, 10);
	}


	setCurrentUserList(listId: number) {
		this.cookieService.set(E.CookieKeys.UserListID, listId.toString(), this.cookieExpirationDate, '/');
		this.userData
		.pipe(take(1))
		.subscribe(
			(data: M.UsersData) => {
				data.List = data.Lists.find(list => list.ListId === listId);
				this.processUserAfterLogin(data);
			}
		);
  }

	removeList(listId: string) {
		return this.registeredNetService.removeList(listId);
	}

	getMyList(listId: number): Observable<M.ListProduct[]> {
		return this.registeredNetService.getMyList(listId);
	}

	/**
	 * USE WITH CAUTION!! This method should be used only if you have newer(updated) version of the UserData than the service!
	 *
	 * @param {M.UsersData} userData
	 * @memberof UserService
	 */
	setUserData(userData: M.UsersData) {
		this._userData.next(userData);
		if (userData.List) {
		this.setCurrentUserList(userData.List.ListId);
		}
	}
}
