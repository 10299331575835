import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as numeral from 'numeral';
import * as M from '../../app.models';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class LocalizationService {
    private clientId: number;
    private selectedLanguage: string;
    public formats: { date: string, number: string, currency: string, currencySymbol: string };
    private _selectedLanguagePromise: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    constructor(private translate: TranslateService,
                private cookieService: CookieService) {
        this.selectedLanguage = 'en';
        this._selectedLanguagePromise.next('en');
        translate.setDefaultLang(this.selectedLanguage);
    }

    setLanguageSiteId(clientId: number, settings: M.LocalizationSettings): void {
        this.clientId = clientId;

        const prefferedLang = this.cookieService.get('preferredLang');
        if (prefferedLang) {
            this.useLanguage(prefferedLang);
        } else {
            this.useLanguage(settings.defaultLanguage);
        }

        this.setPipeLocalizationSettings(settings);
    }

    useLanguage(lang: string = this.selectedLanguage): void {
        this.selectedLanguage = lang;
        if (this.cookieService.get('preferredLang')) {
            this.cookieService.delete('preferredLang', '/'); // delete old one.
        }
        this.cookieService.set('preferredLang', lang, 60, '/'); // 2 months
        this._selectedLanguagePromise.next(lang);
        this.translate.use(`${lang}-${this.clientId}`)
        .subscribe(() => {},
            (e) => {
            console.log(e);
            console.error(`Language ${lang.toUpperCase()} not found or parsing failed, loading EN language instead...`);
            this.selectedLanguage = 'en';
            this._selectedLanguagePromise.next(this.selectedLanguage);
            this.translate.use(`${this.selectedLanguage}-${this.clientId}`);
            this.cookieService.delete('preferredLang', '/');
            this.cookieService.set('preferredLang', this.selectedLanguage, 60, '/');
        });
    }


    get getSelectedLanguage(): Observable<string> {
		return this._selectedLanguagePromise.asObservable();
    }

    getCurrentLanguage(): string {
        return this.selectedLanguage;
    }

    setPipeLocalizationSettings(settings: M.LocalizationSettings) {

        this.formats = {
            date: settings.dateFormat || 'dd/MM/yyyy',
            number: settings.numberFormat || '0,0.00',
            currency: settings.currencyFormat || '$0,0.00',
            currencySymbol: settings.currencySymbol || '$'
        };

        numeral.register('locale', 'registry-locale', <any>{
            delimiters: {
                thousands: settings.delimiters.thousands || ',',
                decimal: settings.delimiters.decimal || '.'
            },
            abbreviations: {
                thousand: 'k',
                million: 'm',
                billion: 'b',
                trillion: 't'
            },
            ordinal: function (number) {
                var b = number % 10;
                return (~~(number % 100 / 10) === 1) ? 'th' :
                    (b === 1) ? 'st' :
                        (b === 2) ? 'nd' :
                            (b === 3) ? 'rd' : 'th';
            },
            currency: {
                symbol: settings.currencySymbol || '$'
            }
        });
        numeral.locale('registry-locale');
    }

}
