import { Injectable, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { SiteService } from '../services/site/site.service';

@Injectable()
export class SiteInitializer {
	constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object) { }

	initialize(): Promise<any> {
		return this.injector.get(SiteService).initSite().toPromise();
	}


	public getParameterByName(name: string) {
		const url = window.location.href.toLowerCase();
		name = name.replace(/[\[\]]/g, '\\$&');
		const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);

		if (!results || !results[2]) {
			return undefined;
		}

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}
}
