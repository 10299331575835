/*
 **** THIS FILE IS AUTOMATICALLY GENERATED ****
 ****          ---  DO NOT  ---            ****
 ****      --- CHANGE IT MANUALLY ---      ****
*/

export const environment = {
  production: false,
	serverUrl: '',
 	version: '2.0.0.060219'
};
