

export enum SpinnerTarget {
	GetBrands,
	GetSettings,
	MarkAsPurchasedSubmit,
	SearchResults,
	InitSite,
	GetRegistryDetails,
	GetRegistryItems,
	CalcTax,
	Checkout,
	PaypalCheckout,
	AddShipping,
	GetStores,
	GetOrderedCategories,
	SearchCatalog,
	GetMyList,
	DeleteListProduct,
	UpdateListProductQuantity,
	Vouchers,
	ShipToMe,
	AddExistingProduct,
	UpdateMarkedBuyer,
	MarkAsBought,
	GetUserVouchers,
	CreateVoucherRequest,
	SettingsViewLoading,
	ShareByEmail,
	AddCustomItem,
	getProduct,
	UploadImage,
	GetVoucher,
	Redeem,
	AzulPayment,
	NotifyRequest
}

export enum ProductChangeAction {
	Added,
	Removed,
	Updated
}
export enum LoginSource {
	Web = 1,
	iPhone = 2, //app
	Android = 3, //app
	CoupleWebsite = 4,
	MobileWeb = 5,
	Tablet = 6, //app
}

export enum Status {
	Enabled,
	Disabled
}
export enum TopPicsCategoriesImage {
	Mobile = 0,
	Desktop = 1
}
export enum LinkType {
	Static = 'static',
	Login = 'login',
	Logout = 'logout',
	Cart = 'cart',
	AppDownload = 'appDownload',
	LiveChat = 'chat',
	Phone = 'phone',
	ExternalUrl = 'externalUrl',
	GuestList = 'guest-list',
	ShareList = 'share-list',
	ChangeList = 'change-list',
	ListLink = 'list-link',
	AddGifts = 'add-gifts',
	newTab = 'newTab',
	Language = 'language'
}

export enum ScanOptionsTitle {
	ScanBarcode = 1,
	EnterItemNumber = 2,
	BrowseCatalog = 3,
	SnapItemPhoto = 4
}

export enum ProductStatus { /*in server fields it called just Status */
	NotAdded = 0,
	Waiting = 1,
	ChipIn = 2,
	Bought = 3,
	Redeem = 4,
	MarkAsBought = 5
}
export enum ProductPurchasedStatus {
	All = 0,
	PartiallyPurchased = 1,
	Purchased = 2,
	Remaining = 3
}

export enum SortOptions {
	PriceLowToHigh,
	PriceHighToLow,
	Category
}

export enum BuyOption {
	Requested1AndNotBought,
	Requested1AndChipedInAmount,
	Requested1AndFullyBought,
	RequestedMoreThan1AndNotBought,
	RequestedMoreThan1AndChipedInQuantity,
	RequestedMoreThan1AndFullyBought,
}

export enum RegistryItemAction {
	GiftIt = 1,
	ChipIn = 2,
	PurchasedInStore = 3
}

export enum RegistryItemPopupState {
	General, // display all available actions
	UpdateItem, // update item state after added to cart
	ChipIn, // display chip in state
	GiftIt,  // display gift it state
	PurchasedInStore  // display purchased in store state
}

export enum EventType {
	Wedding = 1,
	BirthDay = 2
}

export enum PaymentType {
	CreditCard = 1,
	PayPal = 2,
	PayU = 3,
	AzulPaymentPage = 4,
	AzulWebService = 5
}

export enum DeliveryOption {
	SelfPickup = 1,
	Shipping = 2,
	Digital = 3,
	DigitalAndShipping = 4,
	DigitalAndPickup = 5
}

export enum CartButtonType {
	ShipToMe = 1,
	ShipToReg = 2,
	PickUpInStore = 3,
	ContactMeForDeliveryOptions = 4,
	RegisteredRequestShipping = 5,
	RegisteredProceedToCheckout = 6,
	MoneyTransfer = 7,
	DigitalMoneyTransfer = 8,
	ShipToMeShippingPickup = 9
}

export enum OptionState {
	On = 1,
	Off = 0
}

export enum ChatTypes {
  Zendesk = 'zendesk',
  LiveChat = 'livechat',
  Olark = 'olark'
}

export enum Source {
	DesktopWeb = 1,
	MobileWeb = 5,
	TabletWeb = 6
}

export enum ShippingSenderReceiver { // Indicates the side originating Or receiving the shipping.
  Couple = 1,
  Guest = 2
}

export enum GAEventCategory {
	Guest = 'guest',
	Couple = 'couple'
}

export enum GAEventAction {
	View = 'view',
	Action = 'action'
}

export enum GAEventLabel {
	HomePage = 'Home page',
	SearchResults = 'Search Results',
	List = 'List',
	Cart = 'Cart',
	Greeting = 'Greeting',
	ShipToMe = 'Ship To Me',
	StartCheckout = 'Start Checkout',
	CompleteCheckout = 'Complete Checkout',
	AddToCart = 'Add To Cart',
	PurchaseInStore = 'Purchase In Store'
}


export enum EventFieldWhichEventImagesPathBasedOn {
	EventType = 1,
	Destination = 2
}


export enum SearchResultFields {
	EventName = 1,
	Registrant = 2,
	CoRegistrant = 3,
	Date = 4,
	StoreDisplayName = 5,
	HoneymoonDate = 6
}

export enum ListProductStatus {
	Remaining = 1,
	PartiallyPurchased = 2,
	PurchasedNotRedeemed = 3,
	Redeemed = 4,
	MarkedAsBought = 5
}

export enum BuyType {
	Normal = 1,
	ChipIn = 2,
	MarkAsBought = 3
}

export enum SearchResultFieldType {
	Text = 1,
	Date = 2
}

export enum SiteType {
	Couples,
	Guests
}

export enum ProductHierarchyLevel {
	Child = 1,
	Parent = 2,
	Grandparent = 3
}

export enum CookieKeys {
  Name = 'LoggedInUserName',
  UserListID = 'LoggedInUserLastListID',
  Jcookielit = 'JCOOKIELIT',
  TKN = 'TKN',
  TKNLISTID = 'TKNLISTID',
  ADMINHEADER = 'ADMINHEADER',
  RefferalCode = 'RefferalCode',
  XSRF = 'X-XSRF-Token'
}


export enum LocalStorageKeys {
  LoggedInUser = 'LoggedInUser',
  ShipToMeCartList = 'shipToMeCartList',
  AvailableFunds = 'availableFunds',
  UserDetails = 'userDetails',
  ShippingCorrelationId = 'shippingCorrelationId',
  CartData = 'cartData'
}

export enum CategoryItemType {
	Slider = 'slider',
	Default = 'default'
}

export enum Variant {
	Color = 'Color',
	Size = 'Size',
	Weight = 'Weight',
	Finish = 'Finish'
}

export enum StaticJsonName {
  	Context = 'context.json',
	RegistryBenefits = 'registry-benefits.json',
	Features = 'features.json',
	PageLinks = 'page-links.json',
	CouplesNavigationLinks = 'navigation-couples.json',
	GuestNavigationLinks = 'navigation-guest.json',
	HowItWorks = 'how-it-works.json'
}


export enum RedeemDataStatus {
	FullyPurchased = 1,
	ChipIn = 2
}


export enum ManageListFilterType {
	All,
	Purchased,
	PartiallyPurchased,
	Remaining,
	GiftTracker
}

export enum  ManageListSortType {
	PriceRange,
	Category,
	Status
}


export enum SortingOptions {
	Newest = 'Newest',
	PriceHighToLow = 'Price: High to Low',
	PriceLowToHigh = 'Price: Low to High',
	Alphapatical = 'Alphapatical'
}

export enum SpecialBrandsCategory {
	Brands = 'brands',
	TopPics = 'topPics'
}

export enum ProductParamMode {
	SiblingsAndSelf,
	ExactSKU,
	SelfAndParents
}
export enum LoginAuthenticationType {
	BasicAuthentication = '1',
	Facebook = '2',
	JCOOKIELIT = '3'
}

export enum TaxType {
	TaxShippingProvince = 1,
	FixedTax = 6
}

export enum Country {
	Canada = 'Canada',
	UnitedState = 'UnitedStates'
}
