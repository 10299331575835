import { Injectable } from "@angular/core";
import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { Router } from "@angular/router";
import { FeaturesService } from "../services/features/features.service";

@Injectable()
export class AccessGuard implements CanActivate {
	constructor(
		private router: Router,
		private featureService: FeaturesService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		// const routePath = route.routeConfig.path;
		const shouldNavigateToLandingPageDirectly = this.featureService.checkSystemFeature();

		if (shouldNavigateToLandingPageDirectly) {
			this.router.navigate(['Event/Aspiranet-Registry']);
		}

		return !shouldNavigateToLandingPageDirectly;
	}
}
