import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { UtilsService } from '../../services/utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class NetService {
	// CR: the methods in this file that retrieve data from the server should have a return type to make it clearer to read the code.

	private imagesPath = 'https://regsitry-dev.s3.amazonaws.com';

	private registryServerUrl: string;
	private siteServerUrl: string;

	// CR: these MUST NOT be here!!! the net service is only for calling the server. Any other thing should be in another service.
	private savedRegistryDetailsData = {}; // this data doesn't change, so we load GetRegistryDetails once.
	private savedRegistryDetailsObserable = {};

	private returnDataForClientid;

	TermsAndConditions: any;

	private XSRF_TOKEN: string = '';

	constructor(private utilsService: UtilsService,
		private cookieService: CookieService,
		private http: HttpClient) {
		this.registryServerUrl = environment.serverUrl + '/services/api/Registry/';
		this.siteServerUrl = environment.serverUrl + '/Services/api/Site/';

		// CR: Why do we need this? can't we use environment?
		if (this.utilsService.isLocalHost()) {
			this.registryServerUrl = 'http://localhost127/registry.Services/api/Registry/';
			this.siteServerUrl = 'http://localhost127/registry.Services/api/Site/';

			// this.registryServerUrl = 'https://dearbornbabydev.jifiti.com/services/api/Registry/';
			// this.siteServerUrl = 'https://dearbornbabydev.jifiti.com/services/api/Site/';
		}

		if (this.utilsService.isLocalHost()) {
			// CR: 'clientid' should be in a const. If there are more query params we're using in the app, then it should be an enum with string values: enum X { ClientId = 'clientid', ... }
			if (this.utilsService.getQueryParams()['clientid']) {
				this.returnDataForClientid = this.utilsService.getQueryParams()[
					'clientid'
				];
				// CR: the string 'returnDataForClientid' should be defined as a const and used in the next lines
				this.cookieService.set(
					'returnDataForClientid',
					this.returnDataForClientid,
					undefined,
					'/'
				);
			} else if (this.cookieService.get('returnDataForClientid')) {
				this.returnDataForClientid = this.cookieService.get(
					'returnDataForClientid'
				);
			}
		}
	}

	/**
	 * @param eventType : 1,2,3 etc (represent wedding, baby shower etc..)
	 * @param eventImageType : header.jpg, background.jpg, icon etc
	 */
	getImageForEventByEventType(
		eventType: number,
		eventImageType: string,
		clientName: string,
		imageOriginFullPath: string
	) {

		if (imageOriginFullPath) {
			return (imageOriginFullPath + '/events/' +
				eventType +
				'/' +
				eventImageType);
		}
		return (
			this.imagesPath +
			'/' +
			encodeURIComponent(clientName) +
			'/events/' +
			eventType +
			'/' +
			eventImageType
		);
	}

	/**
	 * @param destination : "Israel", "London" .. etc
	 * @param eventImageType : header.jpg, background.jpg, icon etc
	 */
	getImageForEventByDestination(
		destination: string,
		eventImageType: string,
		clientName: string
	) {
		return (
			this.imagesPath + '/' +
			encodeURIComponent(clientName) +
			'/destination/' + eventImageType + '_' +
			encodeURIComponent(destination.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')) +
			'.jpg'
		);
	}

	public getRegistryServerUrl(): string {
		return this.registryServerUrl;
	}

	public getSiteServerUrl(): string {
		return this.siteServerUrl;
	}

	public getServiceUrl(basicUrl: string): string {
		if (this.returnDataForClientid) {
			return `${basicUrl}?clientId=${this.returnDataForClientid}`;
		}
		return basicUrl;
	}

	getEvents(
		query: string,
		language = 'en',
		sortBy = 'date',
		reverseSort = 'false',
		p = ''
	) {
		return this.http.get(
			this.getServiceUrl(this.siteServerUrl + 'SearchRegistry'),
			{
				params: {
					q: query,
					language: language,
					s: sortBy,
					direction: reverseSort,
					p: p
				}
			}
		);
	}

	getRegistryItems(registryId: string, language = 'en'): Observable<any> {
		return this.http.get(
			this.getServiceUrl(this.siteServerUrl + 'GetRegistryItems'),
			{
				params: { id: registryId, language: language }
			}
		);
	}

	getRegistryDetails(eventUniqueName: string) {
		// CR: the logics in this method should be in another service, not here
		if (this.savedRegistryDetailsData[eventUniqueName]) {
			return of(this.savedRegistryDetailsData[eventUniqueName]);
		}

		if (this.savedRegistryDetailsObserable[eventUniqueName]) {
			// if `this.savedRegistryDetailsObserable` is set then the request is in progress
			// return the `Observable` for the ongoing request
			return this.savedRegistryDetailsObserable[eventUniqueName];
		}

		this.savedRegistryDetailsObserable[eventUniqueName] = this.http
			.get(
				this.getServiceUrl(this.siteServerUrl + 'GetRegistryDetails'),
				{
					params: { registry: eventUniqueName }
				}
			)
			.pipe(
				map(res => {
					// when the cached data is available we don't need the `Observable` reference anymore
					this.savedRegistryDetailsObserable[eventUniqueName] = null;

					this.savedRegistryDetailsData[eventUniqueName] = res;
					return this.savedRegistryDetailsData[eventUniqueName];
				})
			)
			.pipe(share()); // make it shared so more than one subscriber can get the result
		return this.savedRegistryDetailsObserable[eventUniqueName];
	}

	getStoresDataReduced(): Observable<M.StoresDataReduced> {
		return this.http.get<M.StoresDataReduced>(
			this.getServiceUrl(this.registryServerUrl + 'GetStoresDataReduced')
		);
	}

	postalCodeLookup(postalCode: string, CountryISO3 = 'USA') {
		return this.http.get(
			this.getServiceUrl(this.registryServerUrl + 'PostalCodeLookup'),
			{
				params: { zipCode: postalCode, CountryISO3: CountryISO3 }
			}
		);
	}

	setAnAppointment(data) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'SetAnAppointment'),
			data
		);
	}

	resetPassword(data) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'resetPassword'),
			data,
			{ params: data }
		);
	}

	calcTax(data: M.CalcTaxRequest, withCredentials: boolean = false): Observable<M.CalcTaxResponse> {
		return this.http.post<M.CalcTaxResponse>(
			this.getServiceUrl(this.siteServerUrl + 'CalcTax'), data, { withCredentials }
		);
	}

	getClientDetails(): Observable<M.ClientDetails> {
		return this.http.get<M.ClientDetails>(
			this.getServiceUrl(this.registryServerUrl + 'getClientDetails')
		);
	}

	getCurrencyDetails(currencyId: number): Observable<M.CurrencyDetails> {
		return this.http.get<M.CurrencyDetails>(
			this.getServiceUrl(this.registryServerUrl + 'getCurrencyDetails?' + 'currencyId=' + currencyId)
		);
	}

	sendOrderNotification(data: M.SendOrderNotification): Observable<M.SendOrderNotificationResult> {
		return this.http.post<M.SendOrderNotificationResult>(
			this.getServiceUrl(this.registryServerUrl + 'SendOrderNotification'),
			data,
			{ withCredentials: true }
		);
	}

	getInventoryDetails(data: M.SendOrderNotification): any {
		return this.http.post(
			this.getServiceUrl(this.registryServerUrl + 'GetInventoryDetails'),
			data,
			{ withCredentials: true }
		);
	}

	updatePayment(data, withCredentials: boolean = false) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'updatePayment'),
			data,
			{ params: data, withCredentials }
		);
	}

	commitPaymentPayPal(data) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'CommitPaymentPayPal'),
			data,
			{ params: data }
		);
	}

	startCheckout(data) {
		data.ForterToken = this.utilsService.getForterToken();
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'StartCheckOut'),
			data
		);
	}

	checkOrderPaymentStatus(orderId: number) {
		return this.http.get(this.getServiceUrl(this.siteServerUrl + 'CheckOrderPaymentStatus?orderid=' + orderId));
	}

	externalCheckOut(data: M.StartCheckoutRequest): Observable<M.ExternalPaymentResponse> {
		// data.ForterToken = this.utilsService.getForterToken();
		return this.http.post<M.ExternalPaymentResponse>(
			this.getServiceUrl(this.siteServerUrl + 'ExternalCheckOut'),
			data
		);
	}

	addShippingRequest(data) {
		// also line 134 in view-cart + on ship to me?
		return this.http.post(
			this.getServiceUrl(this.registryServerUrl + 'AddShippingRequest'),
			data
		);
	}

	purchasedInStore(data) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'BuyInStore'),
			data
		);
	}

	getCouplesNavigationLinks(clientId) {
		return this.http.get('assets/data/site' + clientId + '/navigation-couples.json');
	}

	getDefaultTopPicks(): Observable<M.TopPicks> {
		return this.http.get<M.TopPicks>(this.getServiceUrl(this.registryServerUrl + 'GetDefaultTopPicks'));
	}

	getTopPicks(listId: string): Observable<M.TopPicks> {
		return this.http.get<M.TopPicks>(this.getServiceUrl(this.registryServerUrl + 'GetTopPicks'), {
			params: { listId: listId },
			withCredentials: true
		});
	}

	/**
	 *
	 * @param {M.Product} category transformed into product
	 * @returns {Observable<Object>} Observable of CreateListProductResponse, check the RegistryController's method AddManualProduct
	 * @memberof NetService
	 *
	 * Creates a product from category and adds it to user's list.
	 */
	createProductAddUsersList(product: M.Product): Observable<Object> {
		return this.http.post<Object>(this.getServiceUrl(this.registryServerUrl + 'AddManualProduct'), product);
	}

	/**
	 * @param {number} clientId client specific id
	 * @param {E.StaticJsonName} jsonName
	 * @returns {Observable<T>}
	 * @memberof NetService
	 *
	 * Generic method to get static JSON from local assets. Returns Observable of the type you provide. All hail the TypeScript.
	 */
	getJSON<T>(clientId: number, jsonName: E.StaticJsonName): Observable<T> {
		const url = `assets/data/site${clientId}/${jsonName}`;
		return this.http.get<T>(url);
	}

	getCategories(listId): Observable<M.Category[]> {
		return this.http.get<M.Category[]>(
			this.getRegistryServerUrl() + 'GetCategories',
			{ withCredentials: true, params: listId }
		);
	}

	sendPayUError(data, withCredentials: boolean = false) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'SendPayUError'),
			data,
			{ params: data, withCredentials }
		);
	}

	updateAzulOrder(orderResponse) {
		return this.http.post(
			this.getServiceUrl(this.siteServerUrl + 'UpdateAzulOrder'),
			{
				...orderResponse
			},
			{withCredentials: false }
		);
	}

	getXsrfToken(): string {
		return this.XSRF_TOKEN;
	}

	setXsrfToken(token) {
		this.XSRF_TOKEN = token;
	}

}
