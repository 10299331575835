import { LocalizationService } from '../localization/localization.service';
import { Injectable } from '@angular/core';
import * as M from '../../app.models';

@Injectable({
	providedIn: 'root'
})
export class FeaturesService {
  private features: M.FeaturesConfiguration;

  constructor(private localization: LocalizationService) { }

  initFeatures(clientId: number, features: M.FeaturesConfiguration, currencyDetails: M.CurrencyDetails, defaultLanguage: string = 'en'): void {
    this.features = features;

    this.loadLanguage(clientId, currencyDetails, defaultLanguage);
  }

  private loadLanguage(clientId: number, currencyDetails: M.CurrencyDetails, defaultLanguage: string = 'en'): void {

    this.localization.setLanguageSiteId(clientId, {
      dateFormat: this.getFeatureParameter('date-format'),
      numberFormat: this.getFeatureParameter('number-format'),
      currencyFormat: this.getFeatureParameter('currency-format'),
      currencySymbol:  currencyDetails.CurrencySymbol || this.getFeatureParameter('currency-symbol'),
      delimiters: {
        thousands: this.getFeatureParameter('delimiters', undefined, 'thousands'),
        decimal: this.getFeatureParameter('delimiters', undefined, 'decimal')
      },
      defaultLanguage
    });
  }

  public getFeature(featureKey: string, variationKey?: string): M.Variation {
    if (!this.features) {
      throw new Error('Feature configuration is missing');
    }
    return this.features.Variations
                    .find(f => f.feature_key === featureKey && (!variationKey || f.variation_key === variationKey));
  }

  public getFeatureParameter(featureKey: string, variationKey?: string, parameterKey?: string, defaultValue?: string): string {
    const feature = this.getFeature(featureKey, variationKey);

    if (!feature) {
      return defaultValue;
    }

    if (!parameterKey) {
      return (feature.parameters.length > 0 ? feature.parameters[0].Value : defaultValue);
    }

    const parameter = feature.parameters.find(p => p.Name === parameterKey);
    if (parameter) {
      return parameter.Value;
    }

    return defaultValue;
  }

  public checkSystemFeature(): boolean {
		const loginFeature = this.getFeature('login');
		const landingPageFeature = this.getFeature('landing-page');
		return loginFeature && loginFeature.variation_key === 'disabled' && landingPageFeature &&
		                                            landingPageFeature.variation_key === 'enabled';
  }
}
