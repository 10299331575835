import { Injectable } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';
import { Subject } from 'rxjs';


@Injectable({
	providedIn: 'root'
})
export class SpinnerService {
  private loadingData = {};
  loadingDataSource: Subject<any> = new Subject();

  constructor() { }

  /***
   * @param spinnerTarget - which element/serivce/etc is in loading mode
   */

  startLoading(spinnerTarget: E.SpinnerTarget, displayOverlay?: boolean, message='') : void {
    this.loadingData[spinnerTarget] = { display: true, displayOverlay: displayOverlay ? displayOverlay : false, message };
    this.loadingDataSource.next(this.loadingData);
  }

  stopLoading(spinnerTarget: E.SpinnerTarget): void {
    this.loadingData[spinnerTarget] = { display: false };
    this.loadingDataSource.next(this.loadingData);
  }

  isLoading(spinnerTarget: E.SpinnerTarget) {
    return (this.loadingData[spinnerTarget] || {}).display;
  }

  stopLoadingAllSpinners(): void {
    this.loadingData = {};
    this.loadingDataSource.next(this.loadingData);
  }
}
