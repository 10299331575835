import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { NetService } from '../../services/net/net.service';
import { tap } from 'rxjs/operators';


@Injectable()
export class ForgeryInterceptor implements HttpInterceptor {
    constructor(private netService: NetService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let modifiedReq = req.clone();
        if (this.netService.getXsrfToken()) {

            modifiedReq = req.clone({
            headers: req.headers.set('XSRF-TOKEN', this.netService.getXsrfToken())
            });
        }
        return next.handle(modifiedReq).pipe(tap(evt => {
            if (evt instanceof HttpResponse) {
                if (evt.headers.get('XSRF-TOKEN')) {
                    this.netService.setXsrfToken(evt.headers.get('XSRF-TOKEN'));
                }
            }
        }));
    }
}
