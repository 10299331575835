import { SiteInitializer } from './initializers/site.initializer';
import { NgModule, APP_INITIALIZER } from '@angular/core';

// CR: Why do we need this here?
import 'hammerjs';

import { routing } from './config/app.routes';
import { AppView } from './views/app/app.view';
import { CookieService } from 'ngx-cookie-service';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FacebookModule } from 'ngx-facebook';
import { ModalModule } from 'ngx-bootstrap/modal';

import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { AccessGuard } from './config/access.guard';
import { UnauthorizedInterceptor } from './registered/interceptors/unauthorized.interceptor';
import { ForgeryInterceptor } from './registered/interceptors/frogery.interceptor';

declare var Hammer: any;

export class MyHammerConfig extends HammerGestureConfig {
    buildHammer(element: HTMLElement) {
        const hammer = new Hammer(element, {
            touchAction: 'pan-y',
        });
        return hammer;
    }
}


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http, 'assets/i18n/');
}

export function initSite(siteInitializer: SiteInitializer) {
	return () => siteInitializer.initialize();
}

@NgModule({
	imports: [
		BrowserAnimationsModule,
		HttpClientModule,
		routing,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		FacebookModule.forRoot(),
		ModalModule.forRoot()
	],
	declarations: [
		AppView,
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initSite,
			deps: [SiteInitializer],
			multi: true
		},
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: MyHammerConfig
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: UnauthorizedInterceptor,
			multi: true,
		},
		{
			provide: HTTP_INTERCEPTORS,
			useClass: ForgeryInterceptor,
			multi: true,
		},
		SiteInitializer,
		CookieService,
		AccessGuard
	],
	bootstrap: [AppView]
})
export class AppModule {}
