import { Injectable, Renderer2 } from '@angular/core';
import * as M from '../../app.models';
import * as E from '../../app.enums';


@Injectable({
	providedIn: 'root'
})
export class ChatService {

  chatSupported = {
    zendesk : false,
		liveChat: false,
		external: false
	};
	externalChatURL: string;

  constructor() {
	}

	initChatFeature(clientDetails: M.ClientDetails) {
		if (clientDetails.Features.chatEnabled && clientDetails.ChatLicence) {
			this.initLiveChat(clientDetails.ChatLicence);
		} else if (clientDetails.Features.zendeskChatEnabled) {
			this.initZendeskChat();
		} else if (clientDetails.Features.chatEnabled && clientDetails.Features.externalChatLink) {
			this.initExternalChat(clientDetails.Features.externalChatLink);
    } else if (clientDetails.Features.chatEnabled && clientDetails.OnlineHelpURL) {
      this.initExternalOnlineChat(clientDetails.OnlineHelpURL);
    }
	}

	private initExternalChat(url: string) {
		this.externalChatURL = url;

		this.chatSupported.external = true;
	}

  initZendeskChat() {
    // @ts-ignore
  	window.zEmbed || function(e,t){ var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src="https://assets.zendesk.com/embeddable_framework/main.js",this.t=+new Date,this.zendeskHost="giftit.zendesk.com",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();

    this.chatSupported.zendesk = true;
  }

  initLiveChat (chatLicence: string) {
    const windowObj = <any>window;
     // @ts-ignore
     windowObj.__lc = window.__lc || {};
     windowObj.__lc.license = chatLicence;

     (function() {
			 var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true; lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s); }
		 )();


      this.chatSupported.liveChat = true;
  }

  initExternalOnlineChat(chatURL) {
    const head = document.getElementsByTagName('head')[0];
		const lc = document.createElement('script');
    lc.type = 'text/javascript';
    lc.id="ze-snippet";
		lc.src = chatURL;
		head.appendChild(lc);
  }

  initOlarkChat(renderer2: Renderer2) {
    let s = renderer2.createElement('script');
      s.type = `text/javascript`;
      s.text = `; (function (o, l, a, r, k, y) {
    if (o.olark) return;
    r = "script"; y = l.createElement(r); r = l.getElementsByTagName(r)[0];
    y.async = 1; y.src = "//" + a; r.parentNode.insertBefore(y, r);
    y = o.olark = function () { k.s.push(arguments); k.t.push(+new Date) };
    y.extend = function (i, j) { y("extend", i, j) };
    y.identify = function (i) { y("identify", k.i = i) };
    y.configure = function (i, j) { y("configure", i, j); k.c[i] = j };
    k = y._ = { s: [], t: [+new Date], c: {}, l: a };
})(window, document, "static.olark.com/jsclient/loader.js");
/* Add configuration calls below this comment */
    olark.identify('3438-612-10-5450');
    olark.configure('system.hb_custom_style', {general: {secondaryColor: '#32004B'}});`;
      renderer2.appendChild(document.body, s);
  }

  openChat() {
    if (this.chatSupported.liveChat) {
			const windowObj = <any>window;
      if (typeof windowObj.LC_API !== 'undefined') {
        windowObj.LC_API.open_chat_window();
      }
    } else if (this.chatSupported.external) {
			window.open(this.externalChatURL);
		}
  }
}
