import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessGuard } from './access.guard';

export const routes: Routes = [
	{
		path: '',
		loadChildren: './guest.module#GuestModule'
	},
	{
		path: 'registered',
		loadChildren: './registered/registered.module#RegisteredModule',
		canActivate: [AccessGuard],
	},

	// Page not found route (MUST BE LAST!)
	{ path: '**', redirectTo: '/404' }
];


export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
