import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError, of} from 'rxjs';
import { catchError } from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {NavigationService} from '../../registered/services/navigation/navigation.service';
import { NetService } from '../../services/net/net.service';
import { SpinnerService } from '../../services/spinner/spinner.service';

const UNATHORIZED_ERROR_CODE = 401;
const FROGERY_ERROR_CODE = 909;

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(private navigationService: NavigationService,
                private spinnerService: SpinnerService,
                private netService: NetService) { }

    private handleAuthError(err: HttpErrorResponse, request: HttpRequest<any>): Observable<any> {
        if (request.url.indexOf(this.netService.getRegistryServerUrl()) > -1) {
            if (err.status === UNATHORIZED_ERROR_CODE || err.status === FROGERY_ERROR_CODE) {
                this.spinnerService.stopLoadingAllSpinners();
                this.navigationService.navigateToLogin(true);
                return of(undefined);
            }
        }
        return throwError(err);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(x => this.handleAuthError(x, request)));
    }
}
