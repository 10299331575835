import { Injectable } from '@angular/core';
import { NetService } from '../../../services/net/net.service';
import * as M from '../../../app.models';
import * as E from '../../../app.enums';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BrowserSelectorService } from '../../../services/browser-selector/browser-selector.service';

@Injectable({
	providedIn: 'root'
})
export class RegisteredNetService {
	constructor(
		private browserSelectorService: BrowserSelectorService,
		private netService: NetService,
		private http: HttpClient
	) { }

	getCategories(): Observable<M.Category[]> {
		return this.http.get<M.Category[]>(
			this.netService.getServiceUrl(this.netService.getRegistryServerUrl() + 'GetCategories'),
			{
				params: { source: this.getLoginSource().toString() },
				withCredentials: true
			}
		);
	}

	updateListProductQuantity(listProductId: number, quantity: number): Observable<M.ListProduct> {
		return this.http.get<M.ListProduct>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'UpdateListProductQuantity'
			),
			{
				params: { listProductId: listProductId.toString(), quantity: quantity.toString() }, withCredentials: true
			}
		);
	}

	deleteListProduct(listProductId: number): Observable<M.UserListDetails> {
		const params = { ListProductId: listProductId.toString() };
		return this.http.get<M.UserListDetails>(
			this.netService.getRegistryServerUrl() + 'DeleteListProduct', { params: params, withCredentials: true });
	}

	getMyList(listId: number): Observable<M.ListProduct[]>  {
		const params = { listId: listId.toString() };
		return this.http.get<M.ListProduct[]>(this.netService.getRegistryServerUrl() + 'GetMyList', { params: params, withCredentials: true });
	}

	getBrands(listId: string): Observable<M.BrandCategory> {
		return <Observable<M.BrandCategory>>this.http.get(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'GetBrands'
			),
			{
				params: { listId: listId },
				withCredentials: true

			}
		);
	}


	AddManualProduct(productData): Observable<M.AddExistingProductResponse> {
		return this.http.post<M.AddExistingProductResponse>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'AddManualProduct'
			),
			productData,
			{ withCredentials: true }
		);
	}

	/**
	 * @param {string} credentials - encoded password and email
	 * @returns {Observable<M.UsersData>} returns observable of UsersData Type object from server in case of success. Returns null with success status(204) if user is not registered  in database.
	 * Sometimes returns 500, without any reason.
	 * @memberof RegisteredNetService
	 */
	loginAndGetUserData(credentials: string, type: string = '', listId?: string, isAdmin?: string, listIdToActive?: number): Observable<M.UsersData> {
		const header = this.getAuthorizedHeader(credentials);
		if (isAdmin) {
			header['JHEADER1'] = '3';
		}
		return this.http.get<M.UsersData>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'Login'
			),
			{
				headers: header,
				params: type === E.LoginAuthenticationType.Facebook ? this.generateClientIdentificationObject(false, true) : this.generateClientIdentificationObject(false, false, listId, listIdToActive),
				withCredentials: true
			}
		);
	}



	/**
	 * @returns {Observable<void>} returns 204 if completed successfully. Disables the user token on the server side.
	 * @memberof RegisteredNetService
	 */
	logout(): Observable<void> {
		return this.http.get<void>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'Logout'
			),
			{ withCredentials: true }
		);
	}

	removeList(listId: string) {
		return this.http.get<void>(this.netService.getServiceUrl(
			this.netService.getRegistryServerUrl() + 'RemoveList'
		), { params: { listId: listId }, withCredentials: true });
	}

	/**
	 * @returns {Observable<M.UsersData>} returns observable of UsersData Type object from server in case of success. Returns null with success status(204) if user is not registered  in database.
	 * Sometimes returns 500, without any reason. THIS METHOD WILL ONLY WORK IF USER AUTHORIZED BEFORE!
	 * @memberof RegisteredNetService
	 */
	loginAuthorizedUser(): Observable<M.UsersData> {

		return this.http.get<M.UsersData>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'Login'
			), {
				params: this.generateClientIdentificationObject(true),
				withCredentials: true
			}
		);
	}

	/**
	 *
	 * @param {string} email address of the user, who forgot the password
	 * @returns {Observable<void>} returns null in case of success, exception if fails
	 * @memberof RegisteredNetService
	 */
	sendResetPasswordEmail(email: string): Observable<void> {
		return this.http.get<void>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'resetPassword'
			),
			{ params: { email: email } }
		);
	}

	uploadImage(image: string): Observable<M.UploadedImageFileDetails> {
		return this.http.post<M.UploadedImageFileDetails>(
			this.netService.getRegistryServerUrl() + 'CreateImage',
			{ imageBase64: image },
			{ withCredentials: true }
		);
	}

	/**
	 * @returns {Observable<M.UsersData>} returns observable of basic UsersData (userid + username) Type object from server in case of success
	 * returns 521, if user already exists
	 * @memberof RegisteredNetService
	 */
	registerAndGetUserData(data: M.LoginData): Observable<M.UsersData> {
		const registerData = Object.assign(
			this.generateClientIdentificationObject(true),
			{
				UserName: data.email,
				Password: data.password
			}
		);

		return this.http.post<M.UsersData>(
			this.netService.getServiceUrl(
				this.netService.getRegistryServerUrl() + 'CreateUser'
			),
			registerData
		);
	}

	getListDetails(): Observable<M.ListDetail[]> {
		return this.http.get<M.ListDetail[]>(`${this.netService.getRegistryServerUrl()}GetListDetails`, { withCredentials: true });
	}

	getDashboardDetails(listId: string): Observable<M.DashboardDetails> {
		return this.http.get<M.DashboardDetails>(
			`${this.netService.getRegistryServerUrl()}GetDashboardDetails?listId=${listId}`, { withCredentials: true });
	}

	saveSettings(settingsRequest: M.UsersData): Observable<M.UsersData> {
		return this.http.post<M.UsersData>(this.netService.getRegistryServerUrl() + 'SaveSettings',
			settingsRequest, { withCredentials: true });
	}

	getMessages(siteId) {
		return this.http.get('assets/data/site' + siteId + '/messages.json');
	}

	loginExternal(): Observable<M.UsersData> {
		return this.http.get<M.UsersData>(
			this.netService.getServiceUrl(this.netService.getRegistryServerUrl() + 'LoginExternal'), { withCredentials: true }
		);
	}

	getProductBySKU(listId, mode, sku, listProductId?): Observable<M.ProductBySKU> {
		const params = {
			listId: listId.toString(),
			mode: mode.toString(),
			sku: sku.toString(),
			ListProductId: listProductId
		};
		return this.http.get<M.ProductBySKU>(
			this.netService.getRegistryServerUrl() + 'GetProductsBySKU', { params: params, withCredentials: true });
	}

	markAsBought(markAsBoughtProduct: M.MarkAsBoughtProduct): Observable<M.ListProduct> {
		return this.http.post<M.ListProduct>(this.netService.getRegistryServerUrl() + 'MarkAsBought', markAsBoughtProduct,
			{ withCredentials: true });
	}
	undoMarkAsBought(undoMarkAsBoughtProduct: M.MarkAsBoughtProduct): Observable<M.ListProduct> {
		return this.http.post<M.ListProduct>(this.netService.getRegistryServerUrl() + 'UndoMarkAsBought', undoMarkAsBoughtProduct,
			{ withCredentials: true });
	}
	addExistingProduct(addExistingProduct: M.AddExistingProduct): Observable<M.AddExistingProductResponse> {
		return this.http.post<M.AddExistingProductResponse>(this.netService.getRegistryServerUrl() + 'AddExistingProduct', addExistingProduct,
			{ withCredentials: true });
	}

	addExistingProducts(addExistingProduct): Observable<M.AddExistingProductResponse> {
		return this.http.post<M.AddExistingProductResponse>(this.netService.getRegistryServerUrl() + 'AddExistingProduct', addExistingProduct,
			{ withCredentials: true });
	}

	updateMarkedBuyer(gifterId: number, email: string, name: string): Observable<void> {
		const params = { buyerId: gifterId.toString(), email: email, name: name };
		return this.http.get<void>(this.netService.getRegistryServerUrl() + 'UpdateMarkedBuyer', { params: params, withCredentials: true });
	}

	createNewEvent(newEventRequest) {
		return this.http.post(this.netService.getRegistryServerUrl() + 'SaveSettings', newEventRequest,
			{ withCredentials: true });
	}

	GetStoresDataReduced() {
		return this.http.get(this.netService.getRegistryServerUrl()  + 'GetStoresDataReduced',	{ withCredentials: true });
	}

	searchCatalog(categoryId: string, listId: number): Observable<M.CatalogProduct[]> {
			return <Observable<M.CatalogProduct[]>>this.http.get(
				this.netService.getServiceUrl(this.netService.getRegistryServerUrl() + 'SearchCatalog'),
				{
					withCredentials: true,
					params: { onlineCategoryId: categoryId, listId: listId.toString()  }
				},
			);
	}

	searchProducts(query: string , listId: number , sortOptionId: number = 3): Observable<M.TopPick[]> {
		return <Observable<M.TopPick[]>>this.http.get(
			this.netService.getServiceUrl(this.netService.getRegistryServerUrl() + 'SearchCatalog'),
			{
				withCredentials: true,
				params: { text: query, listId: listId.toString(), sortOptionId: sortOptionId.toString()  }
			},
		);
	}

	getUserVouchers(listId, source) {
		return this.http.get(`${this.netService.getRegistryServerUrl()}GetUserVouchers?listId=${listId}&source=${source}`,
			{ withCredentials: true });
	}

	getVoucher(voucherId) {
		return this.http.get(this.netService.getRegistryServerUrl() + 'GetVoucher', { params: {voucherId }, withCredentials: true });
	}

	getRedeemData(id: number, order: number, lang: string): Observable<M.RedeemData> {
		return this.http.get<M.RedeemData>(
			this.netService.getServiceUrl(this.netService.getSiteServerUrl() + 'GetRedeemData/' + id),
			{ params: { order: order.toString(), lang: lang } }
		);
	}

	createVoucherRequest(listId) {
		return this.http.get(this.netService.getRegistryServerUrl() + 'RequestVoucher', { params: {listId }, withCredentials: true });
	}

	getOrderedCategoriesByBrandName(brandName: string, listId: string): Observable<M.CatalogProduct[]> {
		return this.http.get<M.CatalogProduct[]>(`${this.netService.getRegistryServerUrl()}GetProductsByBrandName`, {
			params: {brandName, listId },
			 withCredentials: true
			});
	}

	sendThanks(gifter: M.SendThanksRequest) {
		return this.http.get<M.CatalogProductExtended>(`${this.netService.getRegistryServerUrl()}SayThanks`, {
			params: {
				GifterId: gifter.GifterId.toString(),
				ListProductId: gifter.ListProductId.toString(),
				Message: gifter.Message
			},
			withCredentials: true
		});
	}

	/**
	 * @param {M.EmailItem} emailItem
	 * @returns {Observable<void>}
	 * @memberof RegisteredNetService
	 *
	 * Shares EmailItem.Content with receivers list from EmailItem.Email. Returns HttpResponse from server;
	 */
	shareListByEmail(emailItem: M.EmailItem): Observable<void> {
		return this.http.post<void>(`${this.netService.getRegistryServerUrl()}SendEmail`, emailItem, { withCredentials: true });
	}

	private getAuthorizedHeader(credentials: string) {
		return {
			'Content-Type': 'application/x-www-form-urlencoded',
			'Cache-Control': 'no-cache',
			'X-Requested-With': 'XMLHttpRequest',
			'Access-Control-Allow-Origin': '*',
			Authorization: 'Basic ' + credentials,
			JHEADERUID: '0',
		};
	}

	private getLoginSource(): E.LoginSource {
		if (this.browserSelectorService.isMobile()) {
			return E.LoginSource.MobileWeb;
		} else {
			return E.LoginSource.CoupleWebsite;
		}
	}

	private generateClientIdentificationObject(jcookie: boolean = false, isFacebook: boolean = false, listId?: string, listIdToActive?: number) {
		return {
			type: this.chackLoginType(jcookie, isFacebook),
			source: this.getLoginSource().toString(),
			appVersion: '6.5.4',
			deviceId: 'a',
			JHEADERUID: '0',
			activeListId: listId,
			...(listIdToActive && listIdToActive !== 0 && {listIdToActive: listIdToActive.toString()})
		};
	}

	chackLoginType(jcookie: boolean, isFacebook: boolean) {
		return jcookie ? E.LoginAuthenticationType.JCOOKIELIT : (isFacebook ? E.LoginAuthenticationType.Facebook : E.LoginAuthenticationType.BasicAuthentication );
	}
}
