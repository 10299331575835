import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AsYouType } from 'libphonenumber-js';
import { ClientDetailsService } from '../clientDetails/client-details.service';


// CR: I don't like 'UtilsService' or similar services... it becomes a service with tons of methods which makes it impossible to maintain. Please separate this file into multiple services (with specific aim) and remove this one.
@Injectable({
	providedIn: 'root'
})
export class UtilsService {

	constructor(private cookieService: CookieService,
		private clientDetailsService: ClientDetailsService) { }

	getQueryParams() {
		// CR: we should use the angular way to get query parameters (look here: https://stackoverflow.com/questions/47455734/how-get-query-parameters-from-url-in-angular-5)
		const params = {};
		const queries = window.location.search.replace('?', '').replace(/&amp;/g, '&').split('&');

		queries.forEach(function (queryString) {
			const query = queryString.split('=');
			if (query[1] && query[1] !== 'undefined' && query[1] !== 'null') {
				params[query[0].toLowerCase()] = decodeURIComponent(query[1]);
			}
		});
		return params;
	}

	checkAndSetKioskMode() {
		// CR: it's better to use string value enum for query parameter names - it's much easier to track down what options exist when we have a list in a central location.
		if (this.getQueryParams()['kiosk'] === '1') {
			// CR: also it's better to use a string value enum for cookie key names. This appears throughout the code here in this file, please change everywhere
			this.cookieService.set('isKiosk', 'true', 3);
		}
	}


	// get number or string represent number and return string of the number with only 2 decimal
	convertToTwoDigitDecimaNumber(number) {
		if (!isNaN(number)) {
			const numberAsString = String(number);
			return numberAsString.match(/^-?\d+(?:\.\d{0,2})?/)[0];
		}
	}

	getForterToken() {
		return this.cookieService.get('forterToken');
	}
	isApp(): boolean {
		return this.getQueryParams()['app'] === 'true';
	}

	isKioskMode(): boolean {
		return this.cookieService.get('isKiosk') === 'true';
	}

	getFirstNameFromFullName(fullname: string): string {
		if (fullname && fullname.split(' ').length) {
			return fullname.split(' ')[0];
		}
	}

	getLastNameFromFullName(fullname: string): string {
		if (fullname && fullname.split(' ').length > 1) {
			return fullname.substring(fullname.split(' ')[0].length + 1);
		}
	}

	cleanPhone(phone: string): string {
		return phone ? phone.replace(/[^\d]/g, '') : '';
	}

	isLocalHost(): boolean {
		return window.location && window.location.hostname.indexOf('localhost') > -1;
	}

	isDevOrQa(): boolean {
		return window.location && (window.location.hostname.indexOf('dev.') > -1 || window.location.hostname.indexOf('qa.') > -1);
	}

	setFavicon(src) {
		document.getElementById('appFavicon').setAttribute('href', src);
	}

	loadCss(siteId) {
		const head = document.getElementsByTagName('head')[0];
		const linkGuest = document.createElement('link');
		linkGuest.rel = 'stylesheet';
		linkGuest.type = 'text/css';
		linkGuest.href = `assets/styles/site-${siteId}/site.css?registryFileVersion=1`;
		linkGuest.media = 'all';
		head.appendChild(linkGuest);

		const linkCouples = document.createElement('link');
		linkCouples.rel = 'stylesheet';
		linkCouples.type = 'text/css';
		linkCouples.href = `assets/styles/site-${siteId}/registered/registered.css?registryFileVersion=1`;
		linkCouples.media = 'all';
		head.appendChild(linkCouples);

		if (this.isKioskMode() && this.clientDetailsService.getClientDetails().Features.customKioskPrint) {
			const linkKiosk = document.createElement('link');
			linkKiosk.rel = 'stylesheet';
			linkKiosk.type = 'text/css';
			linkKiosk.href = `assets/styles/kiosk.css?registryFileVersion=1`;
			linkKiosk.media = 'all';
			head.appendChild(linkKiosk);
		}
	}

	trimHtml(html, options) {
		options = options || {};

		const limit = options.limit || 100,
			preserveTags = (typeof options.preserveTags !== 'undefined') ? options.preserveTags : true,
			wordBreak = (typeof options.wordBreak !== 'undefined') ? options.wordBreak : false,
			suffix = options.suffix || '...',
			moreLink = options.moreLink || '';

		const arr = html.replace(/</g, '\n<')
			.replace(/>/g, '>\n')
			.replace(/\n\n/g, '\n')
			.replace(/^\n/g, '')
			.replace(/\n$/g, '')
			.split('\n');

		let sum = 0,
			row, cut, add,
			tagMatch,
			tagName,
			tagStack = [],
			more = false;

		for (let i = 0; i < arr.length; i++) {

			row = arr[i];
			// count multiple spaces as one character
			const rowCut = row.replace(/[ ]+/g, ' ');

			if (!row.length) {
				continue;
			}

			if (row[0] !== '<') {

				if (sum >= limit) {
					row = '';
				} else if ((sum + rowCut.length) >= limit) {

					cut = limit - sum;

					if (row[cut - 1] === ' ') {
						while (cut) {
							cut -= 1;
							if (row[cut - 1] !== ' ') {
								break;
							}
						}
					} else {

						add = row.substring(cut).split('').indexOf(' ');

						// break on halh of word
						if (!wordBreak) {
							if (add !== -1) {
								cut += add;
							} else {
								cut = row.length;
							}
						}
					}

					row = row.substring(0, cut) + suffix;

					if (moreLink) {
						row += '<a href="' + moreLink + '" style="display:inline">»</a>';
					}

					sum = limit;
					more = true;
				} else {
					sum += rowCut.length;
				}
			} else if (!preserveTags) {
				row = '';
			} else if (sum >= limit) {

				tagMatch = row.match(/[a-zA-Z]+/);
				tagName = tagMatch ? tagMatch[0] : '';

				if (tagName) {
					if (row.substring(0, 2) !== '</') {

						tagStack.push(tagName);
						row = '';
					} else {

						while (tagStack[tagStack.length - 1] !== tagName && tagStack.length) {
							tagStack.pop();
						}

						if (tagStack.length) {
							row = '';
						}

						tagStack.pop();
					}
				} else {
					row = '';
				}
			}

			arr[i] = row;
		}

		return {
			html: arr.join('\n').replace(/\n/g, ''),
			more: more
		};
	}

  scrollToTop(document: Document) {
	window.scroll(0, 1);
	document.body.scrollTop = 0;
	}

	formatPhone(value, countryCode) {
		if (!value) {
			return value;
		}
		const asYouType: AsYouType = this.getFormatter(countryCode);
		if (asYouType) {
			let newVal = value.replace(/\D/g, '');
			newVal = asYouType.input(newVal);
			return newVal;
		}
		return value;
	}
	getFormatter(countryCode): AsYouType {
		let asYouType: AsYouType = null;

		switch (countryCode) {
			case 'us':
				asYouType = new AsYouType('US');
				break;
			case 'jp':
				asYouType = new AsYouType('JP');
				break;
			case 'ca':
				asYouType = new AsYouType('CA');
				break;
			case 'ph':
				asYouType = new AsYouType('PH');
				break;
			case 'mx':
				asYouType = new AsYouType('MX');
				break;
			case 'be':
				asYouType = new AsYouType('BE');
				break;
			case 'lu':
				asYouType = new AsYouType('LU');
				break;
			case 'de':
				asYouType = new AsYouType('DE');
				break;
			case 'br':
				asYouType = new AsYouType('BR');
				break;
		}
		return asYouType;
	}
}
